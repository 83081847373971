import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import {
  getDetailDepenseCharge,
  getListCharges,
  updateDepenseCharge,
} from "../../../redux/actions/designationActions";
import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";

function EditDepenseChargeScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  //
  const [designationCharge, setDesignationCharge] = useState("");
  const [designationChargeError, setDesignationChargeError] = useState("");

  const [itemsCharge, setItemCharge] = useState([]);
  const [selectItemsCharge, setSelectItemCharge] = useState([]);
  const [selectItemsChargeError, setSelectItemChargeError] = useState("");

  const [designationDate, setDesignationDate] = useState("");
  const [designationDateError, setDesignationDateError] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");

  const [avanceType, setAvanceType] = useState("");
  const [avanceTypeError, setAvanceTypeError] = useState("");

  const [numberReglement, setNumberReglement] = useState("");
  const [numberReglementError, setNumberReglementError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [eventType, setEventType] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listCharge = useSelector((state) => state.chargeList);
  const { charges, loadingCharge, errorCharge, successCharge } = listCharge;

  const detailDepenseCharge = useSelector(
    (state) => state.getDetailDepenseCharge
  );
  const {
    loadingDepenseChargeDetail,
    errorDepenseChargeDetail,
    successDepenseChargeDetail,
    depenseCharge,
  } = detailDepenseCharge;

  const depenseChargeUpdate = useSelector((state) => state.updateDepenseCharge);
  const {
    loadingDepenseChargeUpdate,
    errorDepenseChargeUpdate,
    successDepenseChargeUpdate,
  } = depenseChargeUpdate;

  const redirect = "/";
  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate(redirect);
  //   } else {
  //    await dispatch(getListCharges());
  //    await dispatch(getDetailDepenseCharge(id));
  //   }
  // }, [navigate, userInfo]);
  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo) {
        navigate(redirect);
      } else {
        await dispatch(getListCharges()); // Fetch list charges first
        await dispatch(getDetailDepenseCharge(id)); // Fetch detail after
      }
    };

    fetchData();
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (depenseCharge !== undefined && depenseCharge !== null) {
      setDesignationCharge(depenseCharge.charge?.id);

      if (depenseCharge.charge?.id) {
        const selectedCharge = charges?.find(
          (charge) => charge.id === Number(depenseCharge.charge?.id)
        );
        // setSelectItemCharge([]);

        if (selectedCharge) {
          setItemCharge(selectedCharge.items ?? []);
        } else {
          setItemCharge([]);
        }
      }

      if (depenseCharge.items && depenseCharge.items?.length > 0) {
        setSelectItemCharge(
          depenseCharge.items?.map((item) => item.subcharge) || []
        );
      } else {
        setSelectItemCharge([]);
      }

      setDesignationDate(depenseCharge.date);

      setAmount(depenseCharge.total_amount);

      setAvanceType(depenseCharge.type_payment);

      setNumberReglement(depenseCharge.number_reglement);

      setNote(depenseCharge.note);
    }
  }, [depenseCharge]);

  useEffect(() => {
    if (successDepenseChargeUpdate) {
      setDesignationCharge("");
      setDesignationChargeError("");

      setItemCharge([]);
      setSelectItemCharge([]);
      setSelectItemChargeError("");

      setDesignationDate("");
      setDesignationDateError("");

      setAmount(0);
      setAmountError("");

      setAvanceType("");
      setAvanceTypeError("");

      setNumberReglement("");
      setNumberReglementError("");

      setNote("");
      setNoteError("");

      dispatch(getDetailDepenseCharge(id));

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successDepenseChargeUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/depenses/charges/">
            <div className="">Charges</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modifié</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Modifié le Charge
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection title="Informations de charge">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Désignations"
                    type="select"
                    placeholder=""
                    value={designationCharge}
                    onChange={(v) => {
                      setDesignationCharge(v.target.value);

                      const selectedCharge = charges?.find(
                        (charge) => charge.id === Number(v.target.value)
                      );
                      setSelectItemCharge([]);

                      if (selectedCharge) {
                        setItemCharge(selectedCharge.items ?? []);
                      } else {
                        setItemCharge([]);
                      }
                    }}
                    error={designationChargeError}
                    options={charges?.map((charge) => ({
                      value: charge.id,
                      label: charge.designation_name,
                    }))}
                  />
                </div>
                {itemsCharge && itemsCharge.length > 0 && (
                  <div className=" w-full my-1 mb-4">
                    <div className="ext-[#898989] text-[14px] px-[5px]  line-clamp-1">
                      Sous Charge
                    </div>
                    <div className="flex flex-wrap">
                      {itemsCharge?.map((item, index) => (
                        <div
                          key={item.id || index} // Ensure a unique key
                          onClick={() => {
                            setSelectItemCharge(
                              (prev) =>
                                prev.includes(item.id)
                                  ? prev.filter((id) => id !== item.id) // Remove if already selected
                                  : [...prev, item.id] // Add if not selected
                            );
                          }}
                          className={`m-1 px-2 py-1 rounded border w-max text-sm cursor-pointer ${
                            selectItemsCharge.includes(item.id)
                              ? "bg-primary text-white"
                              : ""
                          }`}
                        >
                          {item.sub_name}
                        </div>
                      ))}
                    </div>
                    {selectItemsChargeError && (
                      <p className="text-[9px] italic text-danger leading-none mt-1">
                        {selectItemsChargeError ?? ""}
                      </p>
                    )}
                  </div>
                )}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Montant"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={amount}
                    onChange={(v) => setAmount(v.target.value)}
                    error={amountError}
                  />
                  <InputModel
                    label="date"
                    type="date"
                    placeholder=""
                    value={designationDate}
                    onChange={(v) => setDesignationDate(v.target.value)}
                    error={designationDateError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type réglement"
                    type="select"
                    placeholder=""
                    value={avanceType}
                    onChange={(v) => setAvanceType(v.target.value)}
                    error={avanceTypeError}
                    options={[
                      { value: "Espece", label: "Espece" },
                      { value: "Cheque", label: "Cheque" },
                      { value: "Carte de credit", label: "Carte de credit" },
                      { value: "Virement", label: "Virement" },
                      {
                        value: "Paiement international",
                        label: "Paiement international",
                      },
                    ]}
                  />
                  <InputModel
                    label="Numéro réglement"
                    type="text"
                    placeholder=""
                    value={numberReglement}
                    onChange={(v) => setNumberReglement(v.target.value)}
                    error={numberReglementError}
                  />
                </div>
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => {
                      setNote(v.target.value);
                    }}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setDesignationChargeError("");
                setSelectItemChargeError("");
                setDesignationDateError("");
                setAmountError("");
                setAvanceTypeError("");
                setNumberReglementError("");
                setNoteError("");
                if (designationCharge === "") {
                  setDesignationChargeError("Ce champ est requis.");
                  check = false;
                }
                if (itemsCharge.length > 0) {
                  if (selectItemsCharge.length === 0) {
                    setSelectItemChargeError("Ce champ est requis.");
                    check = false;
                  }
                }
                if (designationDate === "") {
                  setDesignationDateError("Ce champ est requis.");
                  check = false;
                }
                if (amount === "" || amount === 0) {
                  setAmountError("Ce champ est requis.");
                  check = false;
                }
                if (avanceType === "") {
                  setAvanceTypeError("Ce champ est requis.");
                  check = false;
                }
                // if (numberReglement === "") {
                //   setNumberReglementError("Ce champ est requis.");
                //   check = false;
                // }

                if (check) {
                  setEventType("update");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir modifé cette Charge ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setDesignationCharge("");
              setDesignationChargeError("");

              setItemCharge([]);
              setSelectItemCharge([]);
              setSelectItemChargeError("");

              setDesignationDate("");
              setDesignationDateError("");

              setAmount(0);
              setAmountError("");

              setAvanceType("");
              setAvanceTypeError("");

              setNumberReglement("");
              setNumberReglementError("");

              setNote("");
              setNoteError("");

              dispatch(getDetailDepenseCharge(id));

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateDepenseCharge(id, {
                  charge: designationCharge,
                  total_amount: amount,
                  date: designationDate,
                  type_payment: avanceType,
                  number_reglement: numberReglement,
                  note: note,
                  items: selectItemsCharge,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditDepenseChargeScreen;
