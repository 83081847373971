import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import {
  addNewDepenseEmploye,
  getListCharges,
} from "../../../redux/actions/designationActions";

import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { getEmployesList } from "../../../redux/actions/employeActions";

function AddDepenseEmployeScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //
  const [employeSelect, setEmployeSelect] = useState("");
  const [employeSelectError, setEmployeSelectError] = useState("");

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");

  const [isSalari, setIsSalari] = useState(false);
  const [isSalariError, setIsSalariError] = useState(false);

  const [salaireDate, setSalaireDate] = useState("");
  const [salaireDateError, setSalaireDateError] = useState("");

  //
  const [designationDate, setDesignationDate] = useState("");
  const [designationDateError, setDesignationDateError] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");

  const [avanceType, setAvanceType] = useState("");
  const [avanceTypeError, setAvanceTypeError] = useState("");

  const [numberReglement, setNumberReglement] = useState("");
  const [numberReglementError, setNumberReglementError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [eventType, setEventType] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listEmployes = useSelector((state) => state.employesList);
  const { employes, loadingEmploye, errorEmploye, pages } = listEmployes;

  const addDepenseEmploye = useSelector(
    (state) => state.createNewDepenseEmploye
  );
  const {
    loadingDepenseEmployeAdd,
    errorDepenseEmployeAdd,
    successDepenseEmployeAdd,
  } = addDepenseEmploye;

  const redirect = "/";
  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo) {
        navigate(redirect);
      } else {
        await dispatch(getListCharges()); // Fetch list charges first
      }
    };

    fetchData();
  }, [navigate, userInfo]);

  useEffect(() => {
    if (successDepenseEmployeAdd) {
      setEmployeSelect("");
      setEmployeSelectError("");

      setIsSalari(false);
      setIsSalariError("");

      setDesignation("");
      setDesignationError("");

      setSalaireDate("");
      setSalaireDateError("");

      setDesignationDate("");
      setDesignationDateError("");

      setAmount(0);
      setAmountError("");

      setAvanceType("");
      setAvanceTypeError("");

      setNumberReglement("");
      setNumberReglementError("");

      setNote("");
      setNoteError("");

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successDepenseEmployeAdd]);
  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/depenses/employes/">
            <div className="">Employés</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter Un Nouveau Charge Employés
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection title="Informations de charge">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Employé"
                    type="select"
                    placeholder=""
                    value={employeSelect}
                    onChange={(v) => setEmployeSelect(v.target.value)}
                    error={employeSelectError}
                    options={employes?.map((employe) => ({
                      value: employe.id,
                      label: employe.first_name + " " + employe.last_name,
                    }))}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Est un salaire?"
                    type="select"
                    placeholder=""
                    value={isSalari}
                    onChange={(v) => {
                      setIsSalari(v.target.value === "true" ? true : false);
                      if (v.target.value === "") {
                        setIsSalariError("Ce champ est requis.");
                      } else if (v.target.value === "true") {
                        setDesignation("Salaire");
                        setSalaireDate("");
                      } else if (v.target.value === "false") {
                        setSalaireDate("");
                      } else if (designation === "Salaire") {
                        setDesignation("");
                      }
                    }}
                    error={isSalariError}
                    options={[
                      { value: true, label: "Oui" },
                      { value: false, label: "Non" },
                    ]}
                  />
                  <InputModel
                    label="Désignation"
                    type="text"
                    placeholder=""
                    disabled={isSalari}
                    value={designation}
                    onChange={(v) => setDesignation(v.target.value)}
                    error={designationError}
                  />
                </div>
                {isSalari ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Date de Salaire"
                      type="date"
                      placeholder=""
                      value={salaireDate}
                      onChange={(v) => setSalaireDate(v.target.value)}
                      error={salaireDateError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Montant"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={amount}
                    onChange={(v) => setAmount(v.target.value)}
                    error={amountError}
                  />
                  <InputModel
                    label="date"
                    type="date"
                    placeholder=""
                    value={designationDate}
                    onChange={(v) => setDesignationDate(v.target.value)}
                    error={designationDateError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type réglement"
                    type="select"
                    placeholder=""
                    value={avanceType}
                    onChange={(v) => setAvanceType(v.target.value)}
                    error={avanceTypeError}
                    options={[
                      { value: "Espece", label: "Espece" },
                      { value: "Cheque", label: "Cheque" },
                      { value: "Carte de credit", label: "Carte de credit" },
                      { value: "Virement", label: "Virement" },
                      {
                        value: "Paiement international",
                        label: "Paiement international",
                      },
                    ]}
                  />
                  <InputModel
                    label="Numéro réglement"
                    type="text"
                    placeholder=""
                    value={numberReglement}
                    onChange={(v) => setNumberReglement(v.target.value)}
                    error={numberReglementError}
                  />
                </div>
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => {
                      setNote(v.target.value);
                    }}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;

                setEmployeSelectError("");
                setIsSalariError("");
                setDesignationError("");
                setSalaireDateError("");
                setDesignationDateError("");
                setAmountError("");
                setAvanceTypeError("");
                setNumberReglementError("");
                setNoteError("");

                if (employeSelect === "") {
                  setEmployeSelect("Ce champ est requis.");
                  check = false;
                }

                if (isSalari === "") {
                  setIsSalariError("Ce champ est requis.");
                  check = false;
                }
                if (designation === "") {
                  setDesignationError("Ce champ est requis.");
                  check = false;
                }
                if (isSalari === true && salaireDate === "") {
                  setSalaireDateError("Ce champ est requis.");
                  check = false;
                }

                if (designationDate === "") {
                  setDesignationDateError("Ce champ est requis.");
                  check = false;
                }
                if (amount === "" || amount === 0) {
                  setAmountError("Ce champ est requis.");
                  check = false;
                }
                if (avanceType === "") {
                  setAvanceTypeError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter cette Charge ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setEmployeSelect("");
              setEmployeSelectError("");

              setIsSalari(false);
              setIsSalariError("");

              setDesignation("");
              setDesignationError("");

              setSalaireDate("");
              setSalaireDateError("");

              setDesignationDate("");
              setDesignationDateError("");

              setAmount(0);
              setAmountError("");

              setAvanceType("");
              setAvanceTypeError("");

              setNumberReglement("");
              setNumberReglementError("");

              setNote("");
              setNoteError("");

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewDepenseEmploye({
                  employe: employeSelect,
                  is_salaire: isSalari ? "True" : "False",
                  designation: designation,
                  date_salaire: salaireDate,
                  total_amount: amount,
                  date: designationDate,
                  type_payment: avanceType,
                  number_reglement: numberReglement,
                  note: note,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddDepenseEmployeScreen;
