import axios from "../../axios";
import {
  CONTRAT_LIST_REQUEST,
  CONTRAT_LIST_SUCCESS,
  CONTRAT_LIST_FAIL,
  //
  CONTRAT_ADD_REQUEST,
  CONTRAT_ADD_SUCCESS,
  CONTRAT_ADD_FAIL,
  //
  CONTRAT_DETAIL_REQUEST,
  CONTRAT_DETAIL_SUCCESS,
  CONTRAT_DETAIL_FAIL,
  //
  CONTRAT_UPDATE_REQUEST,
  CONTRAT_UPDATE_SUCCESS,
  CONTRAT_UPDATE_FAIL,
  //
  CONTRAT_CLIENT_LIST_REQUEST,
  CONTRAT_CLIENT_LIST_SUCCESS,
  CONTRAT_CLIENT_LIST_FAIL,
  //
  CONTRAT_PAYMENT_LIST_REQUEST,
  CONTRAT_PAYMENT_LIST_SUCCESS,
  CONTRAT_PAYMENT_LIST_FAIL,
  //
  CONTRAT_PAYMENT_ADD_REQUEST,
  CONTRAT_PAYMENT_ADD_SUCCESS,
  CONTRAT_PAYMENT_ADD_FAIL,
  //
  CONTRAT_PAYMENT_DETAIL_REQUEST,
  CONTRAT_PAYMENT_DETAIL_SUCCESS,
  CONTRAT_PAYMENT_DETAIL_FAIL,
  //
  CONTRAT_PAYMENT_UPDATE_REQUEST,
  CONTRAT_PAYMENT_UPDATE_SUCCESS,
  CONTRAT_PAYMENT_UPDATE_FAIL,
  //
  CONTRAT_PAYMENT_DELETE_REQUEST,
  CONTRAT_PAYMENT_DELETE_SUCCESS,
  CONTRAT_PAYMENT_DELETE_FAIL,
  //
  CONTRAT_RETURN_ADD_REQUEST,
  CONTRAT_RETURN_ADD_SUCCESS,
  CONTRAT_RETURN_ADD_FAIL,
  //
  CONTRAT_BACK_LIST_REQUEST,
  CONTRAT_BACK_LIST_SUCCESS,
  CONTRAT_BACK_LIST_FAIL,
  //
  CONTRAT_FACTURES_LIST_REQUEST,
  CONTRAT_FACTURES_LIST_SUCCESS,
  CONTRAT_FACTURES_LIST_FAIL,
  //
  SEARCH_CONTRAT_LIST_REQUEST,
  SEARCH_CONTRAT_LIST_SUCCESS,
  SEARCH_CONTRAT_LIST_FAIL,
  //
  CONTRAT_RETURN_VALID_REQUEST,
  CONTRAT_RETURN_VALID_SUCCESS,
  CONTRAT_RETURN_VALID_FAIL,
  //
  CONTRAT_DELETE_REQUEST,
  CONTRAT_DELETE_SUCCESS,
  CONTRAT_DELETE_FAIL,
  //
} from "../constants/contratConstant";

// delete contrat
export const deleteContrat = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/contrats/delete/${id}/`, config);

    dispatch({
      type: CONTRAT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// valid return
export const validReturnContrat = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_RETURN_VALID_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      `/contrats/return/${id}/valid/`,
      {},
      config
    );

    dispatch({
      type: CONTRAT_RETURN_VALID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_RETURN_VALID_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// search contrat
export const searchListContrats = (search) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_CONTRAT_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/contrats/search/${search}/`, config);

    dispatch({
      type: SEARCH_CONTRAT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: SEARCH_CONTRAT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list factures contrats
export const getFacturesListContrats = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_FACTURES_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/contrats/factures/?page=${page}`,
      config
    );

    dispatch({
      type: CONTRAT_FACTURES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_FACTURES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list back contrats
export const getBackListContrats =
  (page, search = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_BACK_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/contrats/back/?page=${page}&search=${search}`,
        config
      );

      dispatch({
        type: CONTRAT_BACK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_BACK_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// delete pyment
export const addReturnContrat = (id, retour) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_RETURN_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/contrats/return/${id}/`, retour, config);

    dispatch({
      type: CONTRAT_RETURN_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_RETURN_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete pyment
export const deleteContratPayments = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_PAYMENT_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/contrats/payments/${id}/delete/`,
      config
    );

    dispatch({
      type: CONTRAT_PAYMENT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_PAYMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update pyment
export const updateContratPayments =
  (id, payment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_PAYMENT_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/contrats/payments/${id}/update/`,
        payment,
        config
      );

      dispatch({
        type: CONTRAT_PAYMENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_PAYMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get detail payment
export const getDetailContratPayment =
  (payment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_PAYMENT_DETAIL_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/contrats/payments/${payment}/detail/`,
        config
      );

      dispatch({
        type: CONTRAT_PAYMENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_PAYMENT_DETAIL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// add new payment
export const addContratPayments =
  (contrat, payment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_PAYMENT_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/contrats/payments/${contrat}/add/`,
        payment,
        config
      );

      dispatch({
        type: CONTRAT_PAYMENT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_PAYMENT_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list contrat payment
export const getListContratPayments =
  (contrat) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_PAYMENT_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/contrats/payments/${contrat}/`,
        config
      );

      dispatch({
        type: CONTRAT_PAYMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_PAYMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list contrat client
export const getListContratClients =
  (client, page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_CLIENT_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/contrats/client/${client}/?page=${page}`,
        config
      );

      dispatch({
        type: CONTRAT_CLIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_CLIENT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// update reservation
export const updateContrat = (id, contrat) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `/contrats/update/${id}/`,
      contrat,
      config
    );

    dispatch({
      type: CONTRAT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail contrat
export const detailContrat = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(`/contrats/detail/${id}/`, config);

    dispatch({
      type: CONTRAT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new client
export const addNewContrats = (contrat) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRAT_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/contrats/add/`, contrat, config);

    dispatch({
      type: CONTRAT_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CONTRAT_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cars
export const getListContrats =
  (page, search = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRAT_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/contrats/?page=${page}&search=${search}`,
        config
      );

      dispatch({
        type: CONTRAT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CONTRAT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };
