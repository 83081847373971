import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addNewClient, clientList } from "../../redux/actions/clientActions";
import LayoutSection from "../../components/LayoutSection";
import { getListAgences } from "../../redux/actions/agenceActions";
import { getMarqueList } from "../../redux/actions/marqueActions";
import { getModelList } from "../../redux/actions/modelActions";
import InputModel from "../../components/InputModel";
import { addNewCar, getListCars } from "../../redux/actions/carActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { addNewReservation } from "../../redux/actions/reservationActions";
import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { baseURL, baseURLFile } from "../../constants";

function RaportScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const today = new Date().toISOString().split("T")[0];
  //
  const [startDateNet, setStartDateNet] = useState("");
  const [startDateNetError, setStartDateNetError] = useState("");
  const [endDateNet, setEndDateNet] = useState("");
  const [endDateNetError, setEndDateNetError] = useState("");

  const [startDateRapport, setStartDateRapport] = useState("");
  const [startDateRapportError, setStartDateRapportError] = useState("");
  const [endDateRapport, setEndDateRapport] = useState("");
  const [endDateRapportError, setEndDateRapportError] = useState("");

  const [startDateReg, setStartDateReg] = useState("");
  const [startDateRegError, setStartDateRegError] = useState("");
  const [endDateReg, setEndDateReg] = useState("");
  const [endDateRegError, setEndDateRegError] = useState("");

  const [startDateImp, setStartDateImp] = useState("");
  const [startDateImpError, setStartDateImpError] = useState("");
  const [endDateImp, setEndDateImp] = useState("");
  const [endDateImpError, setEndDateImpError] = useState("");

  const [selectCar, setSelectCar] = useState("");
  const [selectCarError, setSelectCarError] = useState("");

  const [isMensuelNet, setIsMensuelNet] = useState(false);
  const [monthNet, setMonthNet] = useState("");
  const [monthNetError, setMonthNetError] = useState("");

  const [isMensuelRapport, setIsMensuelRapport] = useState(false);
  const [monthRapport, setMonthRapport] = useState("");
  const [monthRapportError, setMonthRapportError] = useState("");

  const [isMensuelImpay, setIsMensuelImpay] = useState(false);
  const [monthImpay, setMonthImpay] = useState("");
  const [monthImpayError, setMonthImpayError] = useState("");

  const [isMensuelReglement, setIsMensuelReglement] = useState(false);
  const [monthReglement, setMonthReglement] = useState("");
  const [monthReglementError, setMonthReglementError] = useState("");

  const [isMensuelCharges, setIsMensuelCharges] = useState(false);
  const [monthCharges, setMonthCharges] = useState("");
  const [monthChargesError, setMonthChargesError] = useState("");

  const [startDateCharge, setStartDateCharge] = useState("");
  const [startDateChargeError, setStartDateChargeError] = useState("");
  const [endDateCharge, setEndDateCharge] = useState("");
  const [endDateChargeError, setEndDateChargeError] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const listCar = useSelector((state) => state.carList);
  const { cars } = listCar;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListCars("0"));
    }
  }, [navigate, userInfo]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Rapport</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Gestion du Rapport
            </h4>
          </div>
          {/*  */}
          <div className="flex flex-col md:flex-row items-stretch h-full">
            <div className="md:w-1/2 w-full px-1 py-1 flex-1">
              <LayoutSection
                title="Bénéfice net"
                styles={"bg-[#4b8df8] text-white font-bold px-3"}
              >
                <div className="md:py-2 md:flex">
                  <div
                    onClick={() => {
                      if (!isMensuelNet) {
                        setMonthNet("");
                        setStartDateNet("");
                        setEndDateNet("");
                      }
                      setIsMensuelNet(true);
                    }}
                    className="flex flex-row items-center cursor-pointer "
                  >
                    {isMensuelNet ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span className={`mx-2 ${isMensuelNet ? "font-bold" : ""}`}>
                      Mensuel
                    </span>
                  </div>
                  <div className="w-3"></div>
                  {/*  */}
                  <div
                    onClick={() => {
                      if (isMensuelNet) {
                        setMonthNet("");
                        setStartDateNet("");
                        setEndDateNet("");
                      }
                      setIsMensuelNet(false);
                    }}
                    className="flex flex-row items-center cursor-pointer"
                  >
                    {!isMensuelNet ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${!isMensuelNet ? "font-bold" : ""}`}
                    >
                      Entre deux dates
                    </span>
                  </div>
                </div>
                {isMensuelNet ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Mois"
                      type="month"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={monthNet}
                      onChange={(v) => {
                        console.log(v.target.value);
                        setMonthNet(v.target.value);
                        if (v.target.value) {
                          console.log("kkk");
                          const [year, month] = v.target.value
                            .split("-")
                            .map(Number);

                          // Calculate the start date as the first day of the selected month
                          const start = new Date(year, month - 1, 1);

                          // Calculate the end date as the last day of the selected month
                          const end = new Date(year, month, 1);

                          // Format dates to YYYY-MM-DD
                          const formattedStartDate = start
                            .toISOString()
                            .slice(0, 10);
                          const formattedEndDate = end
                            .toISOString()
                            .slice(0, 10);

                          setStartDateNet(formattedStartDate);
                          setEndDateNet(formattedEndDate);
                        }
                      }}
                      error={monthNetError}
                    />
                  </div>
                ) : null}

                {!isMensuelNet ? (
                  <div className="md:py-2 md:flex  md:mt-0 mt-5">
                    <InputModel
                      label="Date début"
                      type="date"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={startDateNet}
                      onChange={(v) => setStartDateNet(v.target.value)}
                      error={startDateNetError}
                    />
                    <InputModel
                      label="Date fin"
                      type="date"
                      placeholder=""
                      isMax={true}
                      max={today}
                      disabled={startDateNet === ""}
                      value={endDateNet}
                      onChange={(v) => setEndDateNet(v.target.value)}
                      error={endDateNetError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 flex justify-end items-center ">
                  <button
                    onClick={() => {
                      setStartDateNetError("");
                      setEndDateNetError("");
                      setMonthNetError("");
                      var check = true;

                      if (startDateNet === "" && !isMensuelNet) {
                        check = false;
                        setStartDateNetError("Ce champ est requis.");
                      }
                      if (endDateNet === "" && !isMensuelNet) {
                        check = false;
                        setEndDateNetError("Ce champ est requis.");
                      }
                      if (monthNet === "" && isMensuelNet) {
                        check = false;
                        setMonthNetError("Ce champ est requis.");
                      }
                      if (check) {
                        const startDate = new Date(startDateNet);
                        const endDate = new Date(endDateNet);

                        // Check if start date is after end date
                        if (startDate > endDate) {
                          window.open(
                            baseURL +
                              `/contrats/rapport-net/?start_date=${endDateNet}&end_date=${startDateNet}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            baseURL +
                              `/contrats/rapport-net/?start_date=${startDateNet}&end_date=${endDateNet}`,
                            "_blank"
                          );
                        }
                      }
                    }}
                    className="bg-[#4b8df8]  text-white px-5 py-1.5 text-center  font-bold rounded"
                  >
                    Afficher
                  </button>
                </div>
              </LayoutSection>
            </div>
            {/*  */}
            <div className="md:w-1/2 w-full px-1 py-1 flex-1">
              <LayoutSection
                title="Rapport par voiture"
                styles={"bg-[#4d5b72] text-white font-bold px-3 "}
              >
                <div className="md:py-2 md:flex">
                  <div
                    onClick={() => {
                      if (!isMensuelRapport) {
                        setMonthRapport("");
                        setStartDateRapport("");
                        setEndDateRapport("");
                      }
                      setIsMensuelRapport(true);
                    }}
                    className="flex flex-row items-center cursor-pointer "
                  >
                    {isMensuelRapport ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${isMensuelRapport ? "font-bold" : ""}`}
                    >
                      Mensuel
                    </span>
                  </div>
                  <div className="w-3"></div>
                  {/*  */}
                  <div
                    onClick={() => {
                      if (isMensuelRapport) {
                        setMonthRapport("");
                        setStartDateRapport("");
                        setEndDateRapport("");
                      }
                      setIsMensuelRapport(false);
                    }}
                    className="flex flex-row items-center cursor-pointer"
                  >
                    {!isMensuelRapport ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${!isMensuelRapport ? "font-bold" : ""}`}
                    >
                      Entre deux dates
                    </span>
                  </div>
                </div>
                <div className="md:py-2 md:flex md:mt-0 mt-5">
                  <InputModel
                    label="Voiture"
                    type="select"
                    placeholder=""
                    value={selectCar}
                    onChange={(v) => setSelectCar(v.target.value)}
                    error={selectCarError}
                    options={cars?.map((car) => ({
                      value: car.id,
                      label:
                        (car.marque.marque_car ?? "---") +
                        " " +
                        (car.model.model_car ?? "") +
                        (car.agence ? " (" + car.agence.name + ") " : "") +
                        " (" +
                        (car.matricule && car.matricule !== ""
                          ? car.matricule
                          : car.ww_matricule && car.ww_matricule !== ""
                          ? car.ww_matricule
                          : "") +
                        ") ",
                      // (car.marque?.marque_car ?? "---") +
                      // " " +
                      // (car.model?.model_car ?? "") +
                      // (car.agence ? " (" + car.agence?.name + ") " : ""),
                    }))}
                  />
                </div>
                {isMensuelRapport ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Mois"
                      type="month"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={monthRapport}
                      onChange={(v) => {
                        console.log(v.target.value);
                        setMonthRapport(v.target.value);
                        if (v.target.value) {
                          console.log("kkk");
                          const [year, month] = v.target.value
                            .split("-")
                            .map(Number);

                          // Calculate the start date as the first day of the selected month
                          const start = new Date(year, month - 1, 1);

                          // Calculate the end date as the last day of the selected month
                          const end = new Date(year, month, 1);

                          // Format dates to YYYY-MM-DD
                          const formattedStartDate = start
                            .toISOString()
                            .slice(0, 10);
                          const formattedEndDate = end
                            .toISOString()
                            .slice(0, 10);

                          setStartDateRapport(formattedStartDate);
                          setEndDateRapport(formattedEndDate);
                        }
                      }}
                      error={monthRapportError}
                    />
                  </div>
                ) : null}
                {!isMensuelRapport ? (
                  <div className="md:py-2 md:flex">
                    <InputModel
                      label="Date début"
                      type="date"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={startDateRapport}
                      onChange={(v) => setStartDateRapport(v.target.value)}
                      error={startDateRapportError}
                    />
                    <InputModel
                      label="Date fin"
                      type="date"
                      placeholder=""
                      isMax={true}
                      disabled={startDateRapport === ""}
                      max={today}
                      value={endDateRapport}
                      onChange={(v) => setEndDateRapport(v.target.value)}
                      error={endDateRapportError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 flex justify-end items-center ">
                  <button
                    onClick={() => {
                      setSelectCarError("");
                      setStartDateRapportError("");
                      setEndDateRapportError("");
                      setMonthRapportError("");
                      var check = true;
                      if (selectCar === "") {
                        check = false;
                        setSelectCarError("Ce champ est requis.");
                      }
                      if (startDateRapport === "" && !isMensuelRapport) {
                        check = false;
                        setStartDateRapportError("Ce champ est requis.");
                      }
                      if (endDateRapport === "" && !isMensuelRapport) {
                        check = false;
                        setEndDateRapportError("Ce champ est requis.");
                      }
                      if (monthRapport === "" && isMensuelRapport) {
                        check = false;
                        setMonthRapportError("Ce champ est requis.");
                      }
                      if (check) {
                        const startDate = new Date(startDateRapport);
                        const endDate = new Date(endDateRapport);

                        // Check if start date is after end date
                        if (startDate > endDate) {
                          window.open(
                            baseURL +
                              `/cars/raport/${selectCar}/?start_date=${endDateRapport}&end_date=${startDateRapport}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            baseURL +
                              `/cars/raport/${selectCar}/?start_date=${startDateRapport}&end_date=${endDateRapport}`,
                            "_blank"
                          );
                        }
                      }
                    }}
                    className="bg-[#4d5b72]   text-white px-5 py-1.5 text-center  rounded"
                  >
                    Afficher
                  </button>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            {/*  */}
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Contrats impayées"
                styles={"bg-[#e02222] text-white font-bold px-3 "}
              >
                <div className="md:py-2 md:flex">
                  <div
                    onClick={() => {
                      if (!isMensuelImpay) {
                        setMonthImpay("");
                        setStartDateImp("");
                        setEndDateImp("");
                      }
                      setIsMensuelImpay(true);
                    }}
                    className="flex flex-row items-center cursor-pointer "
                  >
                    {isMensuelImpay ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${isMensuelImpay ? "font-bold" : ""}`}
                    >
                      Mensuel
                    </span>
                  </div>
                  <div className="w-3"></div>
                  {/*  */}
                  <div
                    onClick={() => {
                      if (isMensuelImpay) {
                        setMonthImpay("");
                        setStartDateImp("");
                        setEndDateImp("");
                      }
                      setIsMensuelImpay(false);
                    }}
                    className="flex flex-row items-center cursor-pointer"
                  >
                    {!isMensuelImpay ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${!isMensuelImpay ? "font-bold" : ""}`}
                    >
                      Entre deux dates
                    </span>
                  </div>
                </div>
                {isMensuelImpay ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Mois"
                      type="month"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={monthImpay}
                      onChange={(v) => {
                        console.log(v.target.value);
                        setMonthImpay(v.target.value);
                        if (v.target.value) {
                          console.log("kkk");
                          const [year, month] = v.target.value
                            .split("-")
                            .map(Number);

                          // Calculate the start date as the first day of the selected month
                          const start = new Date(year, month - 1, 1);

                          // Calculate the end date as the last day of the selected month
                          const end = new Date(year, month, 1);

                          // Format dates to YYYY-MM-DD
                          const formattedStartDate = start
                            .toISOString()
                            .slice(0, 10);
                          const formattedEndDate = end
                            .toISOString()
                            .slice(0, 10);

                          setStartDateImp(formattedStartDate);
                          setEndDateImp(formattedEndDate);
                        }
                      }}
                      error={monthImpayError}
                    />
                  </div>
                ) : null}
                {!isMensuelImpay ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Date début"
                      type="date"
                      isMax={true}
                      max={today}
                      placeholder=""
                      value={startDateImp}
                      onChange={(v) => setStartDateImp(v.target.value)}
                      error={startDateImpError}
                    />
                    <InputModel
                      label="Date fin"
                      type="date"
                      isMax={true}
                      max={today}
                      disabled={startDateImp === ""}
                      placeholder=""
                      value={endDateImp}
                      onChange={(v) => setEndDateImp(v.target.value)}
                      error={endDateImpError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 flex justify-end items-center ">
                  <button
                    onClick={() => {
                      setStartDateImpError("");
                      setEndDateImpError("");
                      setMonthImpayError("");
                      var check = true;
                      if (startDateImp === "" && !isMensuelImpay) {
                        check = false;
                        setStartDateImpError("Ce champ est requis.");
                      }
                      if (endDateImp === "" && !isMensuelImpay) {
                        check = false;
                        setEndDateImpError("Ce champ est requis.");
                      }
                      if (monthImpay === "" && isMensuelImpay) {
                        check = false;
                        setMonthImpayError("Ce champ est requis.");
                      }
                      if (check) {
                        const startDate = new Date(startDateImp);
                        const endDate = new Date(endDateImp);

                        // Check if start date is after end date
                        if (startDate > endDate) {
                          window.open(
                            baseURL +
                              `/contrats/rapport-impayes/?start_date=${endDateImp}&end_date=${startDateImp}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            baseURL +
                              `/contrats/rapport-impayes/?start_date=${startDateImp}&end_date=${endDateImp}`,
                            "_blank"
                          );
                        }
                      }
                    }}
                    className="bg-[#e02222]  text-white px-5 py-1.5 text-center  rounded"
                  >
                    Afficher
                  </button>
                </div>
              </LayoutSection>
            </div>
            {/*  */}
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Réglement"
                styles={"bg-[#852b99] text-white font-bold px-3 "}
              >
                <div className="md:py-2 md:flex">
                  <div
                    onClick={() => {
                      if (!isMensuelReglement) {
                        setMonthReglement("");
                        setStartDateReg("");
                        setEndDateReg("");
                      }
                      setIsMensuelReglement(true);
                    }}
                    className="flex flex-row items-center cursor-pointer "
                  >
                    {isMensuelReglement ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${
                        isMensuelReglement ? "font-bold" : ""
                      }`}
                    >
                      Mensuel
                    </span>
                  </div>
                  <div className="w-3"></div>
                  {/*  */}
                  <div
                    onClick={() => {
                      if (isMensuelReglement) {
                        setMonthReglement("");
                        setStartDateReg("");
                        setEndDateReg("");
                      }
                      setIsMensuelReglement(false);
                    }}
                    className="flex flex-row items-center cursor-pointer"
                  >
                    {!isMensuelReglement ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${
                        !isMensuelReglement ? "font-bold" : ""
                      }`}
                    >
                      Entre deux dates
                    </span>
                  </div>
                </div>
                {isMensuelReglement ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Mois"
                      type="month"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={monthReglement}
                      onChange={(v) => {
                        console.log(v.target.value);
                        setMonthReglement(v.target.value);
                        if (v.target.value) {
                          console.log("kkk");
                          const [year, month] = v.target.value
                            .split("-")
                            .map(Number);

                          // Calculate the start date as the first day of the selected month
                          const start = new Date(year, month - 1, 1);

                          // Calculate the end date as the last day of the selected month
                          const end = new Date(year, month, 1);

                          // Format dates to YYYY-MM-DD
                          const formattedStartDate = start
                            .toISOString()
                            .slice(0, 10);
                          const formattedEndDate = end
                            .toISOString()
                            .slice(0, 10);

                          setStartDateReg(formattedStartDate);
                          setEndDateReg(formattedEndDate);
                        }
                      }}
                      error={monthReglementError}
                    />
                  </div>
                ) : null}
                {!isMensuelReglement ? (
                  <div className="md:py-2 md:flex  md:mt-0 mt-5">
                    <InputModel
                      label="Date début"
                      type="date"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={startDateReg}
                      onChange={(v) => setStartDateReg(v.target.value)}
                      error={startDateRegError}
                    />
                    <InputModel
                      label="Date fin"
                      type="date"
                      placeholder=""
                      isMax={true}
                      disabled={startDateReg === ""}
                      max={today}
                      value={endDateReg}
                      onChange={(v) => setEndDateReg(v.target.value)}
                      error={endDateRegError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 flex justify-end items-center ">
                  <button
                    onClick={() => {
                      setStartDateRegError("");
                      setEndDateRegError("");
                      setMonthReglementError("");
                      var check = true;
                      if (startDateReg === "" && !isMensuelReglement) {
                        check = false;
                        setStartDateRegError("Ce champ est requis.");
                      }
                      if (endDateReg === "" && !isMensuelReglement) {
                        check = false;
                        setEndDateRegError("Ce champ est requis.");
                      }
                      if (monthReglement === "" && isMensuelReglement) {
                        check = false;
                        setMonthReglementError("Ce champ est requis.");
                      }
                      if (check) {
                        const startDate = new Date(startDateReg);
                        const endDate = new Date(endDateReg);

                        // Check if start date is after end date
                        if (startDate > endDate) {
                          window.open(
                            baseURL +
                              `/contrats/rapport-reglement/?start_date=${endDateReg}&end_date=${startDateReg}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            baseURL +
                              `/contrats/rapport-reglement/?start_date=${startDateReg}&end_date=${endDateReg}`,
                            "_blank"
                          );
                        }
                      }
                    }}
                    className="bg-[#852b99]   text-white px-5 py-1.5 text-center  rounded"
                  >
                    Afficher
                  </button>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            {/* charge and entretien */}
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Charges & Entretiens"
                styles={"bg-[#852b99] text-white font-bold px-3 "}
              >
                <div className="md:py-2 md:flex">
                  <div
                    onClick={() => {
                      if (!isMensuelCharges) {
                        setMonthCharges("");
                        setStartDateCharge("");
                        setEndDateCharge("");
                      }
                      setIsMensuelCharges(true);
                    }}
                    className="flex flex-row items-center cursor-pointer "
                  >
                    {isMensuelCharges ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${isMensuelCharges ? "font-bold" : ""}`}
                    >
                      Mensuel
                    </span>
                  </div>
                  <div className="w-3"></div>
                  {/*  */}
                  <div
                    onClick={() => {
                      if (isMensuelCharges) {
                        setMonthCharges("");
                        setStartDateCharge("");
                        setEndDateCharge("");
                      }
                      setIsMensuelCharges(false);
                    }}
                    className="flex flex-row items-center cursor-pointer"
                  >
                    {!isMensuelCharges ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <div className="size-5 border rounded-full"></div>
                    )}
                    <span
                      className={`mx-2 ${!isMensuelCharges ? "font-bold" : ""}`}
                    >
                      Entre deux dates
                    </span>
                  </div>
                </div>
                {isMensuelCharges ? (
                  <div className="md:py-2 md:flex md:mt-0 mt-5">
                    <InputModel
                      label="Mois"
                      type="month"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={monthCharges}
                      onChange={(v) => {
                        console.log(v.target.value);
                        setMonthCharges(v.target.value);
                        if (v.target.value) {
                          console.log("kkk");
                          const [year, month] = v.target.value
                            .split("-")
                            .map(Number);

                          // Calculate the start date as the first day of the selected month
                          const start = new Date(year, month - 1, 1);

                          // Calculate the end date as the last day of the selected month
                          const end = new Date(year, month, 1);

                          // Format dates to YYYY-MM-DD
                          const formattedStartDate = start
                            .toISOString()
                            .slice(0, 10);
                          const formattedEndDate = end
                            .toISOString()
                            .slice(0, 10);

                          setStartDateCharge(formattedStartDate);
                          setEndDateCharge(formattedEndDate);
                        }
                      }}
                      error={monthChargesError}
                    />
                  </div>
                ) : null}
                {!isMensuelCharges ? (
                  <div className="md:py-2 md:flex  md:mt-0 mt-5">
                    <InputModel
                      label="Date début"
                      type="date"
                      placeholder=""
                      isMax={true}
                      max={today}
                      value={startDateCharge}
                      onChange={(v) => setStartDateCharge(v.target.value)}
                      error={startDateChargeError}
                    />
                    <InputModel
                      label="Date fin"
                      type="date"
                      placeholder=""
                      isMax={true}
                      disabled={startDateCharge === ""}
                      max={today}
                      value={endDateCharge}
                      onChange={(v) => setEndDateCharge(v.target.value)}
                      error={endDateChargeError}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 flex justify-end items-center ">
                  <button
                    onClick={() => {
                      setStartDateChargeError("");
                      setEndDateChargeError("");
                      setMonthChargesError("");
                      var check = true;
                      if (startDateCharge === "" && !isMensuelCharges) {
                        check = false;
                        setStartDateChargeError("Ce champ est requis.");
                      }
                      if (endDateCharge === "" && !isMensuelCharges) {
                        check = false;
                        setEndDateChargeError("Ce champ est requis.");
                      }
                      if (monthCharges === "" && isMensuelCharges) {
                        check = false;
                        setMonthChargesError("Ce champ est requis.");
                      }
                      if (check) {
                        const startDate = new Date(startDateCharge);
                        const endDate = new Date(endDateCharge);

                        // Check if start date is after end date
                        if (startDate > endDate) {
                          window.open(
                            baseURL +
                              `/contrats/rapport-charges/?start_date=${endDateCharge}&end_date=${startDateCharge}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            baseURL +
                              `/contrats/rapport-charges/?start_date=${startDateCharge}&end_date=${endDateCharge}`,
                            "_blank"
                          );
                        }
                      }
                    }}
                    className="bg-[#852b99]   text-white px-5 py-1.5 text-center  rounded"
                  >
                    Afficher
                  </button>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="h-10"></div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default RaportScreen;
