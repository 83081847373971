import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import {
  deleteContrat,
  getBackListContrats,
  getListContrats,
  validReturnContrat,
} from "../../redux/actions/contratActions";
import { baseURLFile } from "../../constants";
import ConfirmationModal from "../../components/ConfirmationModal";

function ContratScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const location = useLocation();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listContrat = useSelector((state) => state.contratList);
  const { contrats, loading, error, pages } = listContrat;

  const listContratBack = useSelector((state) => state.backContratList);
  const { backContrats, loadingBackContrat, errorBackContrat } =
    listContratBack;

  const contratReturnValid = useSelector((state) => state.validReturnContrat);
  const {
    loadingContratValidReturn,
    successContratValidReturn,
    errorContratValidReturn,
  } = contratReturnValid;

  const [contratId, setContratId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [searchReturn, setSearchReturn] = useState(
    searchParams.get("research") || ""
  );

  const contratDelete = useSelector((state) => state.deleteContrat);
  const { loadingContratDelete, errorContratDelete, successContratDelete } =
    contratDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListContrats(page, search));
      dispatch(getBackListContrats("0", searchReturn));
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (search) params.set("search", search);
    if (searchReturn) params.set("research", searchReturn);

    // Add default page
    params.set("page", "1");

    // Update URL
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, [search, searchReturn, dispatch, navigate, location.pathname]);

  useEffect(() => {
    if (successContratValidReturn) {
      dispatch(getListContrats(1, search));
      dispatch(getBackListContrats("0", searchReturn));
      setContratId("");
      setLoadEvent(false);
      setEventType("");
      setIsAdd(false);
    }
  }, [successContratValidReturn]);

  useEffect(() => {
    if (successContratDelete) {
      dispatch(getListContrats(1, search));
      dispatch(getBackListContrats("0", searchReturn));
      setContratId("");
      setLoadEvent(false);
      setEventType("");
      setIsAdd(false);
    }
  }, [successContratDelete]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Contrat</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des Contrats
            </h4>
            <Link
              to={"/contrats/add"}
              className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </Link>
          </div>
          <div className="flex md:flex-row flex-col items-center">
            <input
              className={` outline-none border border-[#F1F3FF]
                px-3 py-2 md:w-1/2 w-full rounded text-sm mx-2 my-1`}
              type="text"
              placeholder="Client Name, Matricule Car, WW Matricule Car ...."
              value={search}
              onChange={(v) => {
                setSearch(v.target.value);
                dispatch(getListContrats("1", v.target.value));
              }}
            />
          </div>

          {/* list */}

          <div className="max-w-full overflow-x-auto mt-3">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left ">
                  <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                    NC
                  </th>
                  {/* <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Date
                  </th> */}
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Client
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Voiture
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Matricule
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Début
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Fin
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    NJ
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Prix/jour
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Montant
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Avance
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Reste
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Status
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Actions
                  </th>
                </tr>
              </thead>
              {/*  */}
              <tbody>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Alert type="error" message={error} />
                ) : (
                  contrats?.map((contrat, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.id}
                        </p>
                      </td>
                      {/* <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.created_at ?? "---"}
                        </p>
                      </td> */}
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.client?.first_name ?? "---"}{" "}
                          {contrat.client?.last_name ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.is_withcar
                            ? (contrat.car?.marque?.marque_car ?? "---") +
                                " " +
                                contrat.car?.model?.model_car ?? ""
                            : (contrat.model_car ?? "---") + " (Sans voiture)"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.car?.matricule &&
                          contrat.car?.matricule !== ""
                            ? contrat.car?.matricule
                            : contrat.car?.ww_matricule &&
                              contrat.car?.ww_matricule !== ""
                            ? contrat.car?.ww_matricule
                            : "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.start_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.end_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.nbr_day ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_day).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_total).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_avance).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(
                            parseFloat(contrat.price_total) -
                              parseFloat(contrat.price_avance)
                          ).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.is_valide
                            ? "Validé á " + contrat.backvalid_date
                            : contrat.is_back
                            ? "Actif"
                            : "En Retour"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setContratId(contrat.id);
                              setIsAdd(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/contrats/edit/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* pdf */}
                          <Link
                            className="mx-1 imprimer-class"
                            rel="noopener"
                            target="_blank"
                            to={
                              baseURLFile +
                              "/api/contrats/print_pdf/" +
                              contrat.id +
                              "/"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </Link>
                          {/* payment */}
                          <Link
                            className="mx-1 paiement-class"
                            to={"/contrats/payments/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                              />
                            </svg>
                          </Link>
                          {/* return  */}
                          {!contrat.is_valide ? (
                            <Link
                              className="mx-1 return-class"
                              to={"/contrats/return/" + contrat.id + "/add"}
                            >
                              <svg
                                className="w-5 h-5 bg-meta-7  rounded p-1 text-white text-center text-xs"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                                />
                              </svg>
                            </Link>
                          ) : null}
                          {/* /contrats/return/:id/add */}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="mb-3">
              <Paginate
                route={"/contrats?"}
                search={""}
                page={page}
                pages={pages}
              />
            </div>
          </div>
        </div>

        {/* list back */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Les retours à valider
            </h4>
          </div>
          <div className="flex md:flex-row flex-col items-center">
            <input
              className={` outline-none border border-[#F1F3FF]
                px-3 py-2 md:w-1/2 w-full rounded text-sm mx-2 my-1`}
              type="text"
              placeholder="Client Name, Matricule Car, WW Matricule Car ...."
              value={searchReturn}
              onChange={(v) => {
                setSearchReturn(v.target.value);
                dispatch(getBackListContrats("0", v.target.value));
              }}
            />
          </div>

          <div className="max-w-full overflow-x-auto mt-3">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left ">
                  <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                    NC
                  </th>
                  {/* <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Le
                  </th> */}
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Client
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Voiture
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Matricule
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Début
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Fin
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    NJ
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Km
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Prix/jour
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Montant
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Avance
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Reste
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                    Actions
                  </th>
                </tr>
              </thead>
              {/*  */}
              <tbody>
                {loadingBackContrat ? (
                  <Loader />
                ) : errorBackContrat ? (
                  <Alert type="error" message={errorBackContrat} />
                ) : (
                  backContrats?.map((contrat, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.id}
                        </p>
                      </td>
                      {/* <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.back_date ?? "---"}
                        </p>
                      </td> */}

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.client?.first_name ?? "---"}{" "}
                          {contrat.client?.last_name ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.is_withcar
                            ? (contrat.car?.marque?.marque_car ?? "---") +
                                " " +
                                contrat.car?.model?.model_car ?? ""
                            : (contrat.model_car ?? "---") + " (Sans voiture)"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.car?.matricule &&
                          contrat.car?.matricule !== ""
                            ? contrat.car?.matricule
                            : contrat.car?.ww_matricule &&
                              contrat.car?.ww_matricule !== ""
                            ? contrat.car?.ww_matricule
                            : "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.start_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.end_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.nbr_day ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.parcour_km ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_day).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_total).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_avance).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(
                            parseFloat(contrat.price_total) -
                              parseFloat(contrat.price_avance)
                          ).toFixed(2) ?? "---"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("deletereturn");
                              setContratId(contrat.id);
                              setIsAdd(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/contrats/edit/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* pdf */}
                          <Link
                            className="mx-1 imprimer-class"
                            rel="noopener"
                            target="_blank"
                            to={
                              baseURLFile +
                              "/api/contrats/print_pdf/" +
                              contrat.id +
                              "/"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </Link>
                          {/* payment */}
                          <Link
                            className="mx-1 paiement-class"
                            to={"/contrats/payments/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                              />
                            </svg>
                          </Link>
                          {/* valid return  */}
                          <div
                            className="mx-1 validreturn-class cursor-pointer"
                            onClick={() => {
                              setContratId(contrat.id);
                              setIsAdd(true);
                              setEventType("valid");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-meta-7  rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m4.5 12.75 6 6 9-13.5"
                              />
                            </svg>
                          </div>
                          {/* /contrats/return/:id/add */}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
                <tr className="h-11"></tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "valid"
              ? "Etes-vous sûr de vouloir confirmer ce retour ?"
              : eventType === "delete"
              ? "Etes-vous sûr de vouloir supprimer cette contrat ?"
              : eventType === "deletereturn"
              ? "Etes-vous sûr de vouloir supprimer le retour de cette contrat ?"
              : "Etes-vous sûr de vouloir confirmer cet operation ?"
          }
          nb={
            eventType === "valid"
              ? "NB: lorsque cette déclaration est validée, l'état de paiement du contrat est passé à payé"
              : ""
          }
          onConfirm={async () => {
            if (eventType === "valid" && contratId !== "") {
              setLoadEvent(true);
              await dispatch(validReturnContrat(contratId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            } else if (eventType === "delete" && contratId !== "") {
              setLoadEvent(true);
              await dispatch(deleteContrat(contratId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            } else if (eventType === "deletereturn" && contratId !== "") {
              setLoadEvent(true);
              await dispatch(deleteContrat(contratId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            } else {
              setLoadEvent(true);
              setContratId("");
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default ContratScreen;
